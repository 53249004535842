@import '../../../../common/styles/abstract/mixins';
@import '../../../../common/styles/abstract/variables';


// .container {
//     width: 50px;
//     height: 50px;
//     background-color: #fff7f7;
//     color: #71d321;
//     border-radius: 50%;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     position: absolute;
//     top: 0;
//     bottom: 0;
//     margin: auto;
//     cursor: pointer;
//     opacity: 0.5;
//     z-index: 2;
// }

.core--btm__arrows {
    @include flexRow(row, space-between);
    width: 130px;

    // @include respond(tab-land) {
    //     width: 30%;
    //     margin-bottom: 2rem;
    //     padding-left: 35%;
    // }

    @include respond(tab-port) {
        width: 20%;
        margin: 2rem 0;
        align-self: center;
    }

    @include respond(phone) {
        width: 30%;
        // padding-left: 35%;
    }
}


.core--btm__arrow {
    position: relative;
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.core--btm__arrows-span {
    font-size: 25px;
    color: $brightlife-white;
    position: absolute;
    top: 10%;
    left: 0;
}

#core--arrows__dark {
    background-color: #71d321;
    cursor: pointer;
}

#core--arrows__light {
    cursor:default;
    background-color: #caefae;
}
