// COLORS
// $color-primary: #356fc7;
// $color-primary-light: #81a1ca;
// $color-primary-dark: #296dd3;
$brightlife-lighten-blue: #EEF7FF;
$brightlife-light-blue: #E3F1FF;
$brightlife-primary-blue: rgba(1, 126, 246, 1);
$brightlife-secondary-blue: #CFE8FF;
$brightlife-blue: #017ef6;
$brightlife-icon-blue: #02273D;

$brightlife-form-white: #E6E6E6;
$brightlife-white: #ffffff;
$brightlife-black: #000000;
$brightlife-primary-black: rgba(27,26,32, 1);
$brightlife-secondary-black: rgba(2,39,61, 1);

$brightlife-light-grey: #f7f7f8;
$brightlife-light-grey-2: rgba(255, 255, 255, 1);
$brightlife-primary-grey: rgba(54, 52, 52, 1);
$brightlife-secondary-grey: rgba(142, 143, 158, 1);
$brightlife-para-grey: rgba(159, 160, 175, 1);
$brightlife-grey: #363434;
$brightlife-text-grey: rgba(69, 90, 102, 1);

$brightlife-lighten-teal: #E7FEF6;
$brightlife-light-teal: #B6FCE4;
$brightlife-primary-teal: #F2F9FF;
$brightlife-teal: #39C59D;
$brightlife-dark-teal: #2DD3A4;

$brightlife-primary-green: #71d321;

$asiiko-icon-background:#E7F8FF;


//BACKGROUND GRADIENT
$rating-gradient: radial-gradient(100% 2386.1% at 0% 49.79%, #262632 0%, #29393C 22.23%, #29393C 33.62%, #252331 100%);

//FONT
$default-font-size: 1.6rem;
$default-font-family: "Montserrat";


//BORDER
$default-border-white: 1.5px solid #FFFFFF;
$icon-border-blue: 1px solid #37B8EA;
$line-border-teal: 3px solid #71d321;

