@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;800&display=swap');
@import url('https://fonts.cdnfonts.com/css/nomada-didone');

//Montserrat font family
@mixin Montserrat-400 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
}
@mixin Montserrat-500 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
}
@mixin Montserrat-600 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
}
@mixin Montserrat-800 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 800;
}
//


//Nomada-Didone font family
@mixin Nomada-Didone-400 {
  font-family: 'Nomada Didone', sans-serif;
  font-weight: 400;                                       
}
@mixin Nomada-Didone-500 {
  font-family: 'Nomada Didone', sans-serif;
  font-weight: 500;                                       
}
@mixin Nomada-Didone-600 {
  font-family: 'Nomada Didone', sans-serif;
  font-weight: 600;                                       
}
@mixin Nomada-Didone-800 {
  font-family: 'Nomada Didone', sans-serif;
  font-weight: 800;                                       
}


@mixin flexBetween($gap) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: $gap;
  }

  @mixin flexRow($direct, $justify) {
    display: flex;
    flex-direction: $direct;
    justify-content: $justify;
  }

  @mixin typographyStyle($size, $weight, $line) {
    font-family: 'Montserrat', sans-serif;
    font-size: $size;
    line-height: $line; 
    font-weight: $weight; 
  }
  
  @mixin typographyStyle2($size, $weight, $line) {
    font-size: $size;
    line-height: $line; 
    font-weight: $weight; 
  }


//MEDIA QUERY MANAGER 
/*
0-600px:   Phone
600-900px:   Tablet portrait
900-1200px:   Tablet landscape
[1200 - 1800] is where our normal styles apply
1800px + :   Big desktop
*/


/*
$breakpoint arguement choices: 
- phone
- tab-port
- tab-land
- big-desktop

ORDER: Base + typography > general layout + grid > page layout > components

1em = 16px
*/
@mixin  respond($breakpoint) {
    @if $breakpoint == phone {
        @media (max-width: 37.5em) { @content }; //600px
    }
    @if $breakpoint == tab-port {
        @media (max-width: 56.25em) { @content }; //900px
    }
    @if $breakpoint == tab-land {
        @media (max-width: 75em) { @content }; //1200px
    }
    @if $breakpoint == big-desktop {
        @media (min-width: 112.5em) { @content }; //1800px
    }
}

// 320px — 480px: Mobile devices.
// 481px — 768px: iPads, Tablets.
// 769px — 1024px: Small screens, laptops.
// 1025px — 1200px: Desktops, large screens.
// 1201px and more — Extra large screens, TV.