@import '../../../common/styles/abstract/mixins';
@import '../../../common/styles/abstract/variables';

.core--container {
  @include flexRow(column, space-between);
  width: 100%;
  // height: 650px;
  height: 100%;
  padding: 5% 7% 2% 7%;

  @include respond(tab-land) {
    padding: 0;
  }

  @include respond(tab-port) {
    @include flexRow(column, flex-start);
    // height: 900px;
  }

  @include respond(phone) {
    // height: 1040px;
  }
}

.core--top {
  @include flexRow(column, space-between);
  margin-bottom: 2rem;

  @include respond(tab-land) {
    margin-top: 4rem;
    padding-left: 5rem;
  }

  @include respond(tab-port) {
    width: 100%;
    margin-bottom: 0%;
  }

  @include respond(phone) {
    width: 100%;
    padding: 0;
  }
}

.core--top__major {
  @include flexRow(column, space-between);

  @include respond(phone) {
    text-align: center;
  }
}

.core--top__head {
  @include typographyStyle(30px, 700, 36.57px);
  color: $brightlife-primary-grey;
}

.core--top__div {
  width: 210px;
  height: 0px;
  border: $line-border-teal;
  margin-bottom: 1.5rem;

  @include respond(phone) {
    align-self: center;
  }
}

.core--top__minor {
  @include respond(phone) {
    padding-left: 13%;
  }
}

.core--top__para {
  @include typographyStyle(18px, 600, 27px);
  width: 40%;
  color: $brightlife-para-grey;

  @include respond(tab-land) {
    width: 50%;
  }

  @include respond(tab-port) {
    width: 80%;
    // padding-left: 10%;
  }

  @include respond(phone) {
    align-self: center;
    text-align: center;
  }
}

.core--bottom__container {
  height: 100%;
  overflow: hidden;
  position: relative;
}

.core--bottom {
  @include flexRow(row, space-between);
  position: relative;
  width: 1208px;
  height: 378px;
  transition: all 900ms cubic-bezier(0.645, 0.045, 0.355, 1);

  @include respond(tab-land) {
    width: 100%;
    height: 400px;
    text-align: center;
    align-items: center;
  }

  @include respond(tab-port) {
    width: 100%;
    height: 670px;
    padding: 0%;
  }

  @include respond(phone) {
    height: 770px;
  }
}

.core--btm {
  @include flexRow(row, flex-start);
  width: 100%;
  text-align: center;

  @include respond(tab-land) {
    @include flexRow(row, flex-start);
    width: 100%;
  }

  @include respond(tab-port) {
    @include flexRow(column, flex-start);
    width: 100%;
  }
}

.core--btm__left {
  @include flexRow(column, flex-start);
  width: 50%;
  text-align: left;
  padding-top: 3rem;



  @include respond(tab-land) {
    text-align: left;
    padding-left: 8%;
  }

  @include respond(tab-port) {
    width: 100%;
    text-align: center;
    padding-top: 1.5rem;
    padding-left: 0;
  }
}

.core--btm__title {
  @include typographyStyle(20px, 700, 24.38px);
  height: 24px;
  color: $brightlife-primary-blue;
  margin-bottom: 1.5rem;

  @include respond(phone) {
    margin-bottom: 1rem;
  }
}


.core--btm__heading {
  width: 424px;
  height: 160px;

  @include respond(tab-land) {
    // width: 454px;
    width: 90%;
  }

  @include respond(tab-port) {
    width: 70%;
    padding: 0 15%;
    text-align: center;
  }

  @include respond(phone) {
    width: 80%;
    padding: 0 10%;
  }
}

#two {
  width: 434px;

  @include respond(tab-land) {
    width: 100%;
  }

  @include respond(tab-port) {
    width: 70%;
  }

  @include respond(phone) {
    width: 70%;
    height: 190px;
    padding: 0 15%;
    margin-bottom: .5rem;
  }
}

#three {
  width: 463px;
  // width: 90%;

  @include respond(tab-land) {
    width: 100%;
  }

  @include respond(tab-port) {
    width: 70%;
    padding: 0 15%;
    text-align: center;
  }

  @include respond(phone) {
    margin-bottom: .5rem;
    height: 190px;
  }
}

.core--btm__head {
  @include typographyStyle(35px, 700, 50px);
  color: $brightlife-primary-grey;
  // overflow-wrap: break-word;
  // hyphens: manual;
  // width: fit-content;


  @include respond(tab-land) {
    @include typographyStyle(28px, 700, 45px);
    margin-top: 2rem;
  }

  @include respond(tab-port) {
    @include typographyStyle(25px, 700, 45px);
    margin-top: 0;
    text-align: center;
  }

  @include respond(phone) {
    @include typographyStyle(22px, 700, 45px);
  }
}

.core--btm__paragraph {
  margin-bottom: 2rem;
  width: 90%;

  @include respond(tab-land) {
    margin-bottom: 1rem;
    width: 100%;
  }

  @include respond(tab-port) {
    width: 70%;
    align-self: center;
  }

  @include respond(phone) {
    width: 80%;
  }
}

.core--btm__para {
  @include typographyStyle(20px, 600, 27px);
  color: $brightlife-black;
}

.core--btm__right {
  width: 50%;
  object-fit: cover;
  
  @include respond(tab-land) {
    padding-top: 3rem;
  }

  @include respond(tab-port) {
    width: 100%;
    text-align: center;
  }
}

.core--btm__right-img {
  width: 428px !important;
  height: 368px !important;
  object-fit: contain;

  @include respond(tab-land) {
    width: 370px !important;
    height: 268px !important;
  }

  //   @include respond(tab-port) {
  //     width: 428px !important;
  // height: 368px !important;
  //   }
}
