@import "../../../common/styles/abstract/mixins";
@import "../../../common/styles/abstract/variables";

.jumbotron--container{
    height: 85vh;
    background-image: linear-gradient(
        to left bottom,
        rgba(248, 251, 246, 0.8),
        rgba(213, 249, 231, 0.8)),
    url("https://res.cloudinary.com/dftu6mjmt/image/upload/v1678349734/Brightlife/fam_s2mgy9.jpg");
    background-size: cover;
    background-position: top;
    position: relative;

    

    @supports (clip-path: polygon(0 0)) or (-webkit-clip-path: polygon(0 0)) {
        -webkit-clip-path: polygon(0 0, 100% 0, 100% 100vh, 0 100%);
         clip-path: polygon(0 0, 100% 0, 100% 100vh, 0 100%); 
         height: 85vh; 
    }
}