@import '../../../common/styles/abstract/mixins';
@import '../../../common/styles/abstract/variables';

.focus--container {
  @include flexRow(column, space-between);
  width: 100%;
  height: 670px;
  padding: 2% 7% 0 7%;
  background-color: #f1f1f1;

  @include respond(tab-land) {
    padding: 0;
  }

  @include respond(tab-port) {
    @include flexRow(column, flex-start);
    height: 900px;
  }

  @include respond(phone) {
    height: 1040px;
  }
}

.focus--top {
  @include flexRow(column, space-between);
  margin-bottom: 2rem;

  @include respond(tab-land) {
    margin-top: 4rem;
    text-align: center;
    align-items: center;  
  }

  @include respond(tab-port) {
    width: 100%;
  }

  @include respond(phone) {
    width: 100%;
  }
}

.focus--top__major {
  @include flexRow(column, space-between);

  // @include respond(tab-land) {
  
  // }
}

.focus--top__head {
  @include typographyStyle(30px, 700, 36.57px);
  color: $brightlife-primary-grey;
}

.focus--top__div {
  width: 210px;
  height: 0px;
  border: $line-border-teal;
  margin-bottom: 1.5rem;

  @include respond(tab-land) {
    align-self: center
  }
}


.focus--top__para {
  @include typographyStyle(18px, 600, 27px);
  width: 40%;
  color: $brightlife-para-grey;

  @include respond(tab-land) {
    width: 100%;
    align-self: center;
  }
}

.focus--bottom__container {
  height: 100%;
  overflow: hidden;
  position: relative;
}

.focus--bottom {
  @include flexRow(row, space-between);
  position: relative;
  width: 100%;
  height: 378px;
  transition: all 900ms cubic-bezier(0.645, 0.045, 0.355, 1);

  @include respond(tab-land) {
    padding: 0 5%;
 }

  @include respond(tab-port) {
    @include flexRow(column, space-between);
    width: 100%;
    height: 100vh;
    padding: 0%;
  }

  @include respond(phone) {
    // height: 770px;
  }
}

.focus--btm__right {
  @include flexRow(column, flex-start);
  width: 50%;

  // @include respond(tab-land) {
  //   width: 100%;
  // }

  @include respond(tab-port) {
    @include flexRow(column, flex-start);
    width: 80%;
    padding-left: 10%;
    margin-bottom: 5rem;
  }

  @include respond(phone) {
    width: 90%;
  }
}


.focus--btm__item{
    @include flexRow(row, flex-start);
    margin-bottom: .5rem;
}

.focus--btm__icon{
    position: relative;
    width: 60px;
    height: 60px;
    border-radius: 5px;
    justify-content: center;
    text-align: center;
    align-items: center;
    background-color: $brightlife-blue;
    margin-right: 1rem;
}

.focus--btm__span{
    position: absolute;
    top: 20%;
    left: 0;
    color: $brightlife-white;
    font-size: 30px;
    z-index: 1;
}


.focus--btm__left {
  width: 50%;
  // padding-left: 10%;
  object-fit: cover;

  @include respond(tab-port) {
    width: 100%;
    text-align: center;
    padding: 0%;
  }
}

.focus--btm__left-img {
  width: 90% !important;
  height: 368px !important;
  object-fit: contain;

  @include respond(tab-land) {
    width: 80% !important;
  }

    @include respond(tab-port) {
      width: 50% !important;
    }

    @include respond(phone) {
      width: 70% !important;
    }
}
