@import "../../../common/styles/abstract/mixins";
@import "../../../common/styles/abstract/variables";

.participant--container {
  width: 100%;
}

.participant--jumbotron {
  width: 100%;
  height: 740px;
  @include flexRow(column, flex-start);
  margin: 5rem 0;
  background-color: #cbf3ab;
  align-items: center;

  @include respond(tab-land) {
    height: 100%;
    padding: 3rem 0 5rem 0;
  }

  @include respond(tab-port) {
  }

  @include respond(phone) {
  }
}
.participant--top {
  width: 100%;
  padding-top: 3rem;

  @include respond(tab-port) {
    width: 70%;
    padding: 0 15%;
  }

  @include respond(phone) {
    width: 80%;
    padding: 0 10%;
  }
}

.participant--top__head {
  @include typographyStyle(20px, 700, 24.38px);
  color: $brightlife-primary-grey;
  text-align: center;
}

.participant--top__subhead-div {
    margin-top: 2rem;
  }

.participant--top__subhead {
  @include typographyStyle(17px, 600, 27px);
  text-align: center;
  color: $brightlife-secondary-grey;
}

.participant--bottom {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 1rem 4rem;
  margin-top: 3rem;
  padding: 0 auto;
  width: 90%;

  @include respond(tab-land) {
    grid-template-columns: auto auto;
    width: 80%;
    place-items: center;
  }

  @include respond(tab-port) {
    grid-template-columns: auto auto;
    gap: 1rem 1rem;
  }

  @include respond(phone) {
    grid-template-columns: auto;
  }
}

.participant--btm {
  @include flexRow(row, space-between);
  width: 320px;
  height: 75px;
  align-items: center;
  background-color: $brightlife-white;
  border-radius: 10px;
  padding: 1rem;

  @include respond(tab-port) {
    width: 100%;
    height: 65px;
    padding: .5rem;
  }

  @include respond(phone) {
    width: 80%;
    height: 80px;
  }
}

.participant--btm__icon {
  position: relative;
  width: 60px;
  height: 60px;
  border-radius: 5px;
  justify-content: center;
  text-align: center;
  align-items: center;
  background-color: $brightlife-blue;
  margin-right: 1rem;
}

.participant--btm__span {
  position: absolute;
  top: 20%;
  left: 0;
  color: $brightlife-white;
  font-size: 30px;
}

.participant--btm__text {
  @include flexRow(column, center);
  width: 80%;
  margin-top: 0.3rem;

  @include respond(tab-port) {
    width: 100%;
    margin-top: 0;
  }
}

.participant--btm__title {
  @include typographyStyle(16px, 600, auto);
  color: $brightlife-primary-grey;

  @include respond(tab-port) {
    @include typographyStyle(14px, 600, 18px);
  }

  @include respond(phone) {
    @include typographyStyle(14.5px, 600, 18px);
  }
}
