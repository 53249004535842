@import "../../../common/styles/abstract/variables";

.Featurebox--top {
  /* position: relative; */
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;


  //tab-land
  @media only screen and (max-width: 1200px) {
    display: grid;
    grid-template-columns: auto auto;
    row-gap: 50px;
    place-items: center;
  }

   //phone
   @media only screen and (max-width: 600px) {
    grid-template-columns: auto;
  }
}

.Featurebox--topp {
  perspective: 150rem;
  -moz-perspective: 150rem;
  height: 30rem;
  transition: all 0.8s ease;
  /* position: absolute; */
  position: relative;
  top: 0;
  left: 0;
  width: 20%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-radius: 3px;
  /* overflow: hidden; */
  box-shadow: 0 1.5rem 4rem rgba($brightlife-black, 0.15);
  background-color: #fff;

  //tab-land
  @media only screen and (max-width: 1200px) {
    width: 20rem;
  }

  //tab-port
  @media only screen and (max-width: 900px) {
    width: 18rem;
  }

    //phone
    @media only screen and (max-width: 600px) {
      width: 20rem;
    }
}

.Featurebox--top__img {
  background-size: cover;
  height: 23rem;
  background-blend-mode: screen;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
  clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.Featurebox--top__span {
  background-size: cover;
  height: 23rem;
  background-blend-mode: screen;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
  clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;

}

.Featurebox--top__head {
  font-size: 1.6rem;
  font-weight: 300;
  text-transform: uppercase;
  text-align: right;
  color: $brightlife-white;
  position: absolute;
  top: 12rem;
  right: 2rem;
  width: 75%;
  height: auto;

    //tab-land
    @media only screen and (max-width: 1200px) {
      width: 100%;
    }
  
    //tab-port
    @media only screen and (max-width: 900px) {
      width: 80%;
    }

}

.Featurebox--top__button{
    width: 12rem;
    height: 3rem;
    border: 2px solid #56a913;
    border-radius: 10rem;
    background-color: #fff;
    margin-top: 1.5rem;
    margin-left: 20%;
    cursor: pointer;
    padding: 0.5rem 1.3rem;
    text-align: center;
    justify-content: center;

      //tab-port
      @media only screen and (max-width: 900px) {
        margin-left: 18%;
      }

       //phone
       @media only screen and (max-width: 600px) {
        margin-left: 20%;
      }
}

.Featurebox--top__link{
  text-align: center;
    text-decoration: none;
    color: #000; 
}

#img1 {
  background-image: linear-gradient(to right bottom, #ffb900, #ff7730),
    url(../../../assets/img/feature4.jpg);
}

#img2 {
  background-image: linear-gradient(to right bottom, #2998ff, #5643fa),
    url(../../../assets/img/counsel2.jpg);
}

#img3 {
  background-image: linear-gradient(to right bottom, #8dd580, #28b485),
    url(../../../assets/img/feature1.jpg);
}

#img4 {
  background-image: linear-gradient(to right bottom, #02070d, #0d0a29),
    url(../../../assets/img/feature3.jpg);
}

#title1 {
  background-image: linear-gradient(
    to right bottom,
    rgba(rgb(255, 185, 0), 0.85),
    rgba(rgb(255, 119, 48), 0.85)
  );
}

#title2 {
  background-image: linear-gradient(
    to right bottom,
    rgba(rgb(41, 152, 255), 0.85),
    rgba(rgb(86, 67, 250), 0.85)
  );
}

#title3 {
  background-image: linear-gradient(
    to right bottom,
    rgba(rgb(128, 214, 113), 0.85),
    rgba(rgb(40, 180, 133), 0.85)
  );
}

#title4 {
  background-image: linear-gradient(
    to right bottom,
    rgba(rgb(216, 229, 245), 0.85),
    rgba(rgb(205, 203, 222), 0.85)
  );
}
