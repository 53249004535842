@import "../../../common/styles/abstract/mixins";
@import "../../../common/styles/abstract/variables";

.programs--container {
  @include flexRow(column, space-between);
  width: 100%;
  align-items: center;
}
.programs--top {
  @include flexRow(column, space-between);
  align-items: center;
  margin-bottom: 4rem;

  @include respond(phone) {
    padding: 0 15%;
    margin-top: 0;
    margin-bottom: 1rem;
  }
}
.programs--top__heading {
  margin-bottom: 1.5rem;
}
.programs--top__head {
  @include typographyStyle(20px, 700, 24.38px);
  color: $brightlife-primary-grey;
  text-align: center;
}

.programs--top__subhead-div{
    margin-top: 1.5rem;
}

.programs--top__subhead {
  @include typographyStyle(15px, 600, 27px);
  color: $brightlife-secondary-grey;
  text-align: center;

  @include respond(phone) {
    width: 90%;
  }
}

.programs--top__btn {
  &-1 {
    @include typographyStyle(17px, 700, 20.72px);
    width: 126px;
    height: 50px;
    border-radius: 25px 0px 0px 25px;
    cursor: pointer;
  }

  &-2 {
    @include typographyStyle(17px, 700, 20.72px);
    width: 126px;
    height: 50px;
    border-radius: 0px 25px 25px 0px;
    cursor: pointer;
  }

  #blue {
    background-color: $brightlife-blue;
    color: $brightlife-white;
    border: 1px solid $brightlife-blue;
  }

  #teal {
    background-color: #dfedfa;
    color: $brightlife-black;
    border: 1px solid #dfedfa;
  }
}

.programs--bottom {
  @include flexRow(row, space-between);
  align-items: center;
  width: 70%;
  height: 100%;

  @include respond(tab-land) {
    width: 90%;
  }

  @include respond(tab-port) {
    @include flexRow(column, flex-start);
    width: 100%;
    height: 100%;
  }

  @include respond(phone) {
    @include flexRow(column, flex-start);
  }
}

.programs--bottoms {
  position: relative;
  padding: 2rem 1rem;
//   width: 469px;
  height: 100%;
  border-radius: 25px;

  @include respond(tab-land) {
    width: 100%;
    height: 864px;
  }

  @include respond(tab-port) {
    width: 60%;
    height: 100%;
  }

  @include respond(phone) {
    width: 80%;
    // height: 700px;
  }
}

#bottom__left {
  margin-right: 5%;
  background-color: $brightlife-light-blue;

  @include respond(tab-port) {
    margin-right: 0;
    margin-bottom: 3rem;
  }

  @include respond(phone) {
    margin-right: 0;
    margin-bottom: 3rem;
  }
}

#bottom__right {
  background-color: $brightlife-lighten-teal;
}

.programs--bottom__head {
  @include typographyStyle(25px, 700, 15px);
  color: $brightlife-secondary-black;

  @include respond(phone) {
    @include typographyStyle(20px, 700, 15px);
  }
}

.programs--bottom__user {
  @include typographyStyle(20px, 500, 10px);
  margin-bottom: 2rem;
  color: $brightlife-text-grey;

  @include respond(phone) {
    @include typographyStyle(18px, 500, 10px);
  }
}

.programs--bottom__plan {
  @include typographyStyle(25px, 700, 40px);
  color: $brightlife-secondary-black;
  margin: .5rem;

  @include respond(phone) {
    @include typographyStyle(20px, 500, 10px);
  }
}
.programs--bottom__line {
  width: 358.21px;
  border: 2px solid #ffffff;
  margin-left: 10%;
  margin-bottom: 10%;

  @include respond(tab-land) {
    width: 80%;
  }

  @include respond(tab-port) {
    width: 80%;
  }

  @include respond(phone) {
    width: 80%;
  }
}


.miniprograms--btm {
  @include flexRow(row, flex-start);
  align-items: center;
}
.miniprograms--btm__plus {
  position: relative;
  width: 29px;
  height: 25px;
  border-radius: 50%;
  background-color: $brightlife-white;
  margin-left: 5%;
  margin-right: 5%;
}
.miniprograms--btm__plus-left {
  position: absolute;
  top: 10%;
  left: -2%;
  color: $brightlife-blue;
}

.miniprograms--btm__plus-right {
  position: absolute;
  top: 10%;
  left: -2%;
  color: #1cb187;
}

.miniprograms--btm__feature {
  @include typographyStyle(18px, 600, 23.94px);
  color: $brightlife-secondary-black;
  margin: 1rem 0;

  @include respond(tab-land) {
    @include typographyStyle(15px, 600, 19.94px);
  }

  @include respond(phone) {
    @include typographyStyle(15px, 600, 19.94px);
  }
}
.programs--bottom__btm {
  position: absolute;
  top: 800px;
  left: 110px;

  @include respond(tab-land) {
    top: 800px;
    left: 0;
    width: 100%;  }

  @include respond(tab-port) {
    top: 730px;
    left: 0;
    width: 100%;  }

  @include respond(phone) {
    left: 0;
    width: 100%;
  }
}

.programs--bottom__right {
  text-align: center;
}

.programs--bottom__right-top{
    text-align: center;
}

.programs--bottom__left-top{
    text-align: center;
}

.programs--bottom__btn {
  width: 266px;
  height: 50px;
  border-radius: 7px;
  @include typographyStyle(12px, 700, 14.63px);
  text-align: center;
  border: none;
  color: white;
  cursor: pointer;

  @include respond(phone) {
    top: 650px;
    left: 60px;
    width: 80%;
  }
}

#btn-left {
  background-color: $brightlife-blue;
}

#btn-right {
  background-color: $brightlife-dark-teal;
}

