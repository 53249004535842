@import "../../../common/styles/abstract/mixins";
@import "../../../common/styles/abstract/variables";



.backToTop {
    position: fixed;
    bottom: 50px;
    right: 50px;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background-color: rgb(248, 251, 246);
    border:1px solid rgb(33, 206, 119);
    font-size: 25px;
    z-index: 2;
    cursor: pointer;
}
