* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  scroll-behavior: smooth;
  width: 100%;
}

.unorderedList:hover {
  color: #56A913;
}

.unorderedList:active {
  color: #f4f2f2;
  background-color: #56A913;
}

.user-nav__icon-box:hover {
  background-color: #f4f2f2;
}

.feature:hover {
  transform: translateY(-1.5rem) scale(1.03);
}

.Explore--img:hover  {
  margin-top: 1rem;
  transform: translateY(-1.5rem) scale(1.03);
}

.Amenities--icon:hover {
  transform: translateY(1.5rem) scale(1.5);
}

/* .review::before {
    content: "\201C";
    position: absolute;
    top: -2.75rem;
    left: -1rem;
    line-height: 1;
    font-size: 20rem;
    color: var(--color-grey-light-2);
    font-family: sans-serif;
    z-index: 1;
} */
/* UserRestaurantComposition */
.composition {
  position: relative;
}
  .composition__photo {
      width: 35%;
      box-shadow: 0 1.5rem 4rem rgba(#000, .4);
      border-radius: 2px;
      position: absolute;
      z-index: 10;
      transition: all .2s;
      outline-offset: 2rem;

      /* @include respond(tab-port) {
          float: left;
          position: relative;
          width: 33.33333333%;
          box-shadow: 0 1.5rem 3rem rgba($color-black, .2);

      } */
    }

      .composition__photo--p1 {
        left: 15%;
        top: 5rem;

          /* @include respond(tab-port) {
              top: 0;
              transform: scale(1.2);
          }     */
      }

      .composition__photo--p2 {
        right: 15%;
        top: 7rem;

          /* @include respond(tab-port) {
              top: -1rem;
              transform: scale(1.3);
              z-index: 100;
          }     */
      }

      .composition__photo--p3 {
        left: 30%;
        top: 15rem;

          /* @include respond(tab-port) {
              top: 1rem;
              left: 0;
              transform: scale(1.1);
          }     */
      }

      .composition__photo:hover {
          outline: 1rem solid #56A913;
          transform: scale(1.05) translateY(-.5rem);
          box-shadow: 0 2.5rem 4rem rgba(#000, .5);
          z-index: 20;
      }

  /* composition:hover composition__photo:not(:hover) */
  .composition:hover .composition__photo:not(:hover) {
      transform: scale(.95);
  }


/* REVIEWS */
.user--review {

}

.review:hover {
  transform: translateY(-1.5rem) scale(1.03);
}

.review--content {

}

.review--user {

}

.review--user__img {

}

.review--user__name {
    
}

.review--user__date {
    
}

.review--user__rating {
    
}

/* Subscribe */
.section-stories {
    position: relative;
    padding: 10rem 0;
  }

  .Subscribe-video {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
    opacity: .15;
    overflow: hidden;
  }
  
  .Subscribe-video__content {
        height: 100%;
        width: 100%;
        object-fit: cover;
  }
  
  .Subscribe-heading{
    margin-bottom: 8rem;
    text-align: center;
  }
  
  .Subscribe-header {
      font-size: 3rem;
      text-transform: uppercase;
      font-weight: 700;
      display: inline-block;
      padding-top: 3rem;
      background-image: linear-gradient(to right, rgb(86, 169, 19), rgb(46, 95, 6),);
      -webkit-background-clip: text;
      color: #56A913;
      letter-spacing: 2px;
      transition: all .2s;
    }

  /* tab-port */
  @media only screen and (max-width: 900px) {
    .Subscribe-header {
      font-size: 2.5rem;
      font-weight: 600;
    }
  }

  /* phone */
  @media only screen and (max-width: 600px) {
    .Subscribe-header {
      font-size: 2rem;
      font-weight: 500;
    }
  }
  
    /* .Subscribe-header:hover {
      transform: skewY(2deg) skewX(15deg) scale(1.1);
      text-shadow: .5rem 1rem 2rem rgba(#000, .2);
    } */
  
    .Subscribe-news {
        position: relative;
        height: 70vh;
        width: 95%;
        padding: 1rem 0rem;
        margin: 5rem 2rem 1rem 2rem;
        border-radius: 15px;
        box-shadow: 20px 20px 50px rgba(0, 0, 0, 0.5);
        background: rgba(255, 255, 255, 0.1);
        overflow: hidden;
        display: flex;
        /* grid-template-columns: 15% auto 25%; */
        justify-content: center;
        align-items: center;
        border-top: 1px solid rgba(255, 255, 255, 0.5);
        border-left: 1px solid rgba(255, 255, 255, 0.5);
        backdrop-filter: blur(5px);
        z-index: 1;
        /* background-image: linear-gradient(to right, #00dbde 0%, #fc00ff 100%); */
    }

          /* tab-port */
      @media only screen and (max-width: 900px) {
        .Subscribe-news {
          margin: 10rem auto 10rem auto;
          height: 100%;
        }
      }
   
  
    .Subscribe-letter {
      display: flex;
      width: 600px;
      flex-direction: column;
      text-align: center;
      justify-content: center;
      align-items: center;
      font-size: 1rem;
    }
  
    .Subscribe-letter h1 {
      font-size: 70px;
      font-weight: 700;
    }

    /* tab-port */
  @media only screen and (max-width: 900px) {
    .Subscribe-letter h1 {
      font-size: 60px;
    }
  }
     /* phone */
  @media only screen and (max-width: 600px) {
    .Subscribe-letter h1 {
      font-size: 50px;
      font-weight: 600;
    }
  }

    .Subscribe-letter__para span {
    color: #56A913;
    font-weight: 900;
    font-family: El Messiri;
    font-size: 1.2rem;
    }

       /* phone */
  @media only screen and (max-width: 600px) {
    .Subscribe-letter__div {
      width: 90%;
    }
  }
  
    .Subscribe-letter__mail {
        color: #56A913;
        text-decoration: none;
        font-family: El Messiri;
    font-size: 1.2rem;
        }

    .Subscribe-letter__timely {
      font-size: 20px;
      font-weight: 300;
      padding: 1rem 0rem;
    }
  
    .Subscribe-letter__Input {
      display: flex;
      flex-direction: row;
      width: 100%;
      height: 2.5rem;
    }

        /* phone */
  @media only screen and (max-width: 600px) {
    .Subscribe-letter__Input {
      width: 90%;
    }
  }
  
    .Subscribe-letter__Input input {
      padding: 0rem 1rem;
    }
  
    .Subscribe-btn__Submit {
      width: 20%;
      margin: 0px 3px;
      color: #fff;
      background-color: #56A913;
      cursor: pointer;
      font-size: 1rem;
       vertical-align: middle;
       font-family: inherit;
       outline-offset: 0px;
       transition-duration: 0.2s;
       transition-timing-function: ease;
       border-radius: 6px;
       border-style: none;
       outline: 0px;
    }

          /* phone */
  @media only screen and (max-width: 600px) {
    .Subscribe-btn__Submit {
      font-size: .8rem;
    }
  }
  
    .Subscribe-btn__Submit:hover {
      width: 25%;
      background-color: gold;
      color: #fff;
      transition-property: width;
      transition-duration: 1s;
      transition-timing-function: ease-in-out;
      transition-delay: .5s;
    }

  
@keyframes moveInLeft {
  0% {
      opacity: 0;
      transform: translateX(-100px);
  }

  /* 60% {
      transform: rotate(45deg);
  } */

  80% {
      transform: translateX(10px);
  }

  100% {
      opacity: 1;
      transform: translate(0);
  }
}

@keyframes moveInRight {
  0% {
      opacity: 0%;
      transform: translateX(100px);
  }
  80% {
      transform: translateX(-10px);
  }
  100% {
      opacity: 1;
      transform: translate(0);
  }
}

@keyframes moveInBottom {
  0% {
      opacity: 0%;
      transform: translateY(-100px);
  }
  80% {
      transform: translateY(10px);
  }
  100% {
      opacity: 1;
      transform: translate(0);
  }
}